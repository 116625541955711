import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import DateTimeDisplay from './DateTimeDisplay';
import Countdown from "react-countdown";
import moment from "moment/moment";
import { useNetwork, useAccount, useSwitchNetwork, useWalletClient } from "wagmi";
import Web3 from "web3/dist/web3.min.js";
import { ethers } from 'ethers';
import Swal from "sweetalert2";
import StakeContractABI from "../contractABI/StakeAbi.json";
import elmoContractAbi from "../contractABI/elmoContractAbi.json";
import nftContractABI from "../contractABI/nftABI.json";
import stake_img from "../images/firered01.png";
import elmo1_img from "../images/elm1.png";
import elmo2_img from "../images/elm2.png";
import stake_title_img from "../images/stake_t.png";
import booster_img from "../images/booster.png";
import stake_img02 from "../images/firered0_1.png";

const elmoContractAddress = process.env.REACT_APP_ELMO_ADDRESS;
const contractStakingAddress = process.env.REACT_APP_ELMO_STAKING_ADDRESS;
const nftContractAddress = process.env.REACT_APP_ELMO_NFT_ADDRESS;
const infuraURL = process.env.REACT_APP_INFURA_URL;

const StakeForm = () => {
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");
  const [countDownCompleted, setCountDownCompleted] = useState(false);
  const {chain, chains} = useNetwork();
  //Set chain to ETH mainnet
  const [chID, setchID] = useState(1);
  const [networkName, setNetworkName] = useState("Ethereum")

  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [approved, setApproved] = useState(false)
  const [decimals, setDecimals] = useState(18);
  const [burnOption, setBurnOption] = useState(0);
  const [stackedBal, setStackedBal] = useState(0);
  const [pending, setPending] = useState(0);
  const [totalBurned, setTotalBurned] = useState(0);
  const [totalUsersStake, setTotalUsersStake] = useState(0);
  const [inStakingPool, setInStakingPool] = useState(0);
  const [regularAPR, setRegularAPR] = useState(0);
  const [boostedAPR, setBoostedAPR] = useState(0);
  const [maxDepositWallet, setMaxDepositWallet] = useState(0);
  const [compRefr, setCompRefr] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [hasStacked, setHasStacked] = useState(false);
  const [widthdrawInitTime, setWidthdrawInitTime] = useState(0);
  const [burnChoosen, setBurnChoosen] = useState(0);
  const [isPoolOnline, setIsPoolOnline] = useState(false);
  const [isCompoundEnabled, setIsCompoundEnabled] = useState(false);
  const [delayTime, setDelayTime] = useState(0);
  const [hasNft, setHasNFT] = useState(0);
  const ref = useRef(null);

  const showAlert = (title, text, icon) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showConfirmButton: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        timer: 3500
      });
  }

  const showLoading = (title) => {
    Swal.fire({
        title: title,
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        didOpen: () => {
          Swal.showLoading()
        },
      });
  }

  const handleApprove = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if(chain.id !== chID ){
      showAlert('Error', 'Switch to '+ networkName +' network', 'error');
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        elmoContractAbi, 
        elmoContractAddress
      );
      showLoading('Approving...')
      await window.elmo_stake.methods
        .approve(contractStakingAddress, amount)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Approved!', 'success')
            setApproved(true);
            handleInput();
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Approve failed, Error: ' + e, 'error')
    }
  }

  useEffect(() => {
    getStackeInfo();
    const intervalId = setInterval(getStackeInfo, 10000); // Every 10 seconds
      return () => clearInterval(intervalId);
  }, [compRefr]);

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer, isConnected, compRefr]);
  
  useEffect(() => {
    if(signer) {
      if(chain !== undefined && chain.id !== chID ) {
        showAlert('Error', 'Switch to '+ networkName +' network', 'error');
        return;
      }
      getUserInfo();
      const intervalId = setInterval(getUserInfo, 2000); // Every 2 seconds
      return () => clearInterval(intervalId);
    }
  }, [provider, signer, isConnected, compRefr]);

  const getStackeInfo = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(infuraURL));
    const stakeContract = new web3.eth.Contract(StakeContractABI, contractStakingAddress);
    try {
      const totalBurned = await stakeContract.methods.totalBurned().call();
      const totalUsersStaking = await stakeContract.methods.totalUsersStake().call();
      const inThePool = await stakeContract.methods.poolTotalReward().call();
      const nftBoostPercentage = await stakeContract.methods.nftBoostPercentage().call();
      const rewPerBlock = await stakeContract.methods.rewardPerBlock().call();
      const maxWallet = await stakeContract.methods.MAX_DEPOSIT_LIMIT().call();
      const poolEnabled = await stakeContract.methods.poolIsOnline().call();
      const compoundEnabled = await stakeContract.methods.compoundEnabled().call();
      const withdrawDelay = await stakeContract.methods.DELAY_WITHDRAW().call();
      setTotalBurned(totalBurned);
      setTotalUsersStake(totalUsersStaking);
      setInStakingPool(inThePool);

      const blockPerYear = (86400/12)*365;
      const rewardsPerBlock = Number(ethers.formatUnits(rewPerBlock, decimals));
      const totalInStake = Number(ethers.formatUnits(totalUsersStaking, decimals));
      const regularAPR = (rewardsPerBlock * blockPerYear) / totalInStake * 100; ///
      //const blockBoosted = Number(rewardsPerBlock + (rewardsPerBlock * Number(nftBoostPercentage) / 100));
      const boostAPR = regularAPR + (regularAPR * Number(nftBoostPercentage) / 100);
      setRegularAPR(regularAPR.toFixed(2));
      setBoostedAPR(boostAPR.toFixed(2));

      setMaxDepositWallet(maxWallet);
      setIsPoolOnline(poolEnabled);
      setIsCompoundEnabled(compoundEnabled);
      setDelayTime(withdrawDelay);
    } catch(e) {
        console.log(e)
    }
  }

  const getUserInfo = async () => {
    if(!signer || !provider || chain.id !== chID) {
      return
    }
    try {
      let web3 = new Web3(new Web3.providers.HttpProvider(infuraURL));
      const tkContract = new web3.eth.Contract(elmoContractAbi, elmoContractAddress);
      const nftContract = new web3.eth.Contract(nftContractABI, nftContractAddress);
      const userBalance = await tkContract.methods.balanceOf(address).call();
      const itHasNFT = await nftContract.methods.balanceOf(address).call();
      setBalance(userBalance);
      setHasNFT(itHasNFT);
      const stakeContract = new web3.eth.Contract(StakeContractABI, contractStakingAddress);
      const stakedInfo = await stakeContract.methods.userInfo(address).call();
      setStackedBal(stakedInfo[0]);
      if(stakedInfo[0] > 0){
        setHasStacked(true)
      }
      setWidthdrawInitTime(stakedInfo[2])
      setBurnChoosen(Number(stakedInfo[3]))
      if(stakedInfo[4] > 0){
        setIsCooldown(true)
      }
      const currentTimestamp = new Date().getTime();
      const withdrawDelay = await stakeContract.methods.DELAY_WITHDRAW().call();
      const endCooldownTimestamp = moment(Number(stakedInfo[2]) * 1000).add(Number(withdrawDelay), "seconds").toDate().getTime();
      if (currentTimestamp < endCooldownTimestamp) {
        setCountDownCompleted(false);
      }
        let pending_rewards = await stakeContract.methods.pendingReward(address).call();
        setPending(pending_rewards[0])
    } catch(error) {
      console.log(error);
    }
  };

  const handleClaimReward = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if (isCooldown) {
      showAlert('Error', 'Withdraw initiated! No rewards to withdraw!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing rewards...')
      await window.elmo_stake.methods
        .withdrawReward()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Withdraw rewards successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw rewards failed. Error: ' + e, 'error')
    }
  }

  const handleCompound = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if (isCooldown) {
      showAlert('Error', 'Cant compound if withdraw initiated. No rewards to compund!', 'error')
      return;
    }
    if(!isCompoundEnabled) {
      showAlert('Error', 'Compound is not enabled!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Compounding...')
      await window.elmo_stake.methods
        .compound()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Compound successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Compound failed, Error: ' + e, 'error')
    }
  }

  const handleUnstake = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing...')
      await window.elmo_stake.methods
        .withdraw()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Withdraw successful!', 'success');
            setHasStacked(false);
            setBurnChoosen(0);
            setIsCooldown(false);
            setCompRefr(!compRefr);
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw failed. Error: ' + e, 'error')
    }
  }

  const handleInitUnstake = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if (isCooldown) {
      showAlert('Error', 'Withdraw already initiated!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Initiating...')
      await window.elmo_stake.methods
        .initWithdraw()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Initiating done!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Initiating failed. Error: ' + e, 'error')
    }
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger me-3'
    },
    buttonsStyling: false
  })

  const handleEmergencyWithdraw = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing...')
      await window.elmo_stake.methods
        .emergencyWithdraw()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Emergency withdraw successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw failed. Error: ' + e, 'error')
    }
  }

  const handleEmergencyMessage = () => {
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to Emergency Unstake? This cannot be undone.',
      text: "Emergency unstaking will deduct 10% of your total staked $ELMO and you will lose any unclaimed rewards.",
      icon: 'warning',
      background: 'rgba(102, 7, 8, .95)',
      color: '#FFFFFF',
      backdrop: `rgba(3, 3, 17, .65)`,
      showCancelButton: true,
      confirmButtonText: 'Accept',
      cancelButtonText: 'Reject',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleEmergencyWithdraw();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: 'Cancelled',
          text: 'Your emergency withdraw was cancelled :)',
          icon: 'error',
          background: 'rgba(3, 3, 17, .95)',
          color: '#FFFFFF',
          backdrop: `rgba(3, 3, 17, .65)`,
          showConfirmButton: false,
          timer: 3500
        });
      }
    })
  }

  const handleInitUnstakeMessage = () => {
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to unstake?',
      text: "This will start the 14d cooldown and no rewards will be generated during the cooldown. Any unclaimed rewards will be added to your unstake amount after the cooldown",
      icon: 'warning',
      background: 'rgba(3, 3, 17, .95)',
      color: '#FFFFFF',
      backdrop: `rgba(3, 3, 17, .65)`,
      showCancelButton: true,
      confirmButtonText: 'Accept',
      cancelButtonText: 'Reject',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleInitUnstake();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: 'Cancelled',
          text: 'Your unstake was cancelled',
          icon: 'error',
          background: 'rgba(3, 3, 17, .95)',
          color: '#FFFFFF',
          backdrop: `rgba(3, 3, 17, .65)`,
          showConfirmButton: false,
          timer: 3500
        });
      }
    })
  }

  const handleInputAmount = (input) => {
    if(!input.target.value || isNaN(input.target.value)) { 
      setAmount(0); 
      return 
    }

    if(chain !== undefined && chain.id !== chID) {
      showAlert('Error', 'Switch to '+ networkName +' network', 'error')
      return
    }

    if (isCooldown) {
      showAlert('Error', 'Cooldown period not passed!', 'error')
      setAmount(0);
      return;
    }
    
    let tempAmnt = ethers.parseUnits(input.target.value, decimals)
    setAmount(input.target.value > 0 ? tempAmnt : 0)

    console.log(amount, "amount")
  };

  const handleBurnOption = (option) => {
    setBurnOption(option);
  }

  const handleInput = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if(burnOption === 0 && burnChoosen === 0) {
      showAlert('Error', 'Select a burn option!', 'error')
      return;
    }
    if(chain.id !== chID) {
      showAlert('Error', 'Switch to '+ networkName +' network', 'error')
      return
    }
    if(!isPoolOnline) {
      showAlert('Error', 'Staking is disabled in this moment!', 'error')
      return
    }
    if (isCooldown) {
      showAlert('Error', 'Cooldown period not passed!', 'error')
      return;
    }
    const burn = burnChoosen !== 0 ? burnChoosen : burnOption;
    try {
      const web3 = new Web3(provider);
      window.elmo_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Staking...')
      await window.elmo_stake.methods
        .deposit(amount, burn)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'You staked!', 'success')
            setApproved(false);
            ref.current.value = '';
            setAmount(0);
            getUserInfo();
            setCompRefr(!compRefr);
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Stake failed, Error: ' + e, 'error')
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
        Period ended!
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
        <div className="show-counter">
          <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Secs'} isDanger={false} />
        </div>
        </>
      );
    }
  };

  return (
    <Row>
      <Col className='col-12 col-lg-12 btm'>
        <div className='general-info'>
          <div className='title'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1197" height="1101" viewBox="0 0 1197 1101" fill="none">
              <g filter="url(#filter0_f_35_8)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M566.697 295.125C602.751 290.492 638.452 300.709 671.517 315.809C708.608 332.747 746.452 352.35 767.919 387.017C790.632 423.698 804.57 469.938 792.629 511.396C780.991 551.805 733.932 568.18 706.549 600.094C678.978 632.228 672.412 688.743 631.409 699.298C590.155 709.918 556.614 664.02 516.77 648.949C480.936 635.395 430.678 647.088 409.311 615.288C387.469 582.783 409.974 538.968 416.98 500.438C421.977 472.955 434.325 449.105 444.275 423.004C455.048 394.741 456.956 362.545 477.872 340.697C501.36 316.161 533.009 299.455 566.697 295.125Z" fill="#BA181B"/>
              </g>
              <defs>
              <filter id="filter0_f_35_8" x="0" y="-106" width="1197" height="1206.88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_35_8"/>
              </filter>
              </defs>
            </svg>
            <div className=''>
              <span className='read'>STEAK</span> TO <br/>EARN & BURN
            </div>
            <span className='subt'>Dynamic staking made simple.</span>
            <a className='stake_button' href='#stake'>
              <img alt='stake' src={stake_img} />
              <span>Fry Your Steak</span>
            </a>
          </div>
          <div className='cards'>
            <img className='elmo1' alt='elmo1' src={elmo1_img} />
            <div className='card'>
              <div className='card-title'>Total Staked</div>
              <div className='card-info'>{Number(ethers.formatUnits(totalUsersStake, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })} $ELMO</div>
            </div>
            <div className='card'>
              <div className='card-title'>Staking Pool</div>
              <div className='card-info'>{Number(ethers.formatUnits(inStakingPool, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })} $ELMO</div>
            </div>
            <div className='card'>
              <div className='card-title'>Total Burned</div>
              <div className='card-info'>{Number(ethers.formatUnits(totalBurned, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })} $ELMO</div>
            </div>
            <img className='elmo2' alt='elmo2' src={elmo2_img} />
          </div>
        </div>
        <div id='stake' className='stake'>
          <div className='info'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1197" height="1207" viewBox="0 0 1197 1207" fill="none">
              <g filter="url(#filter0_f_35_87)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M566.697 401.125C602.751 396.492 638.452 406.709 671.517 421.809C708.608 438.747 746.452 458.35 767.919 493.017C790.632 529.698 804.57 575.938 792.629 617.396C780.991 657.805 733.932 674.18 706.549 706.094C678.978 738.228 672.412 794.743 631.409 805.298C590.155 815.918 556.614 770.02 516.77 754.949C480.936 741.395 430.678 753.088 409.311 721.288C387.469 688.783 409.974 644.968 416.98 606.438C421.977 578.955 434.325 555.105 444.275 529.004C455.048 500.741 456.956 468.545 477.872 446.697C501.36 422.161 533.009 405.455 566.697 401.125Z" fill="#BA181B"/>
              </g>
              <defs>
                <filter id="filter0_f_35_87" x="0" y="0" width="1197" height="1206.88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_35_87"/>
                </filter>
              </defs>
            </svg>
            <img alt='stake' src={stake_title_img} />
            <div className='title_and_subtitle'>
              <div className='title'>Staking</div>
              <div className='subtitle'>Dynamic staking made simple.</div>
            </div>
          </div>
          <div className='stake-card'>
            <div className='cards'>
              <div className='card'>
                <div className='card-title'>Regular APY</div>
                <div className='card-info'>{regularAPR}%</div>
              </div>
              <div className='card'>
                <img className={`booster ${Number(boostedAPR) > 0 && Number(hasNft) > 0 ? '' : 'no_booster'}`} alt='NFT booster' src={booster_img} />
                <div className='card-title'>NFT boosted APY</div>
                <div className='card-info'>{boostedAPR}%</div>
              </div>
            </div>
            <div className='amount-input'>
                <div className='imput-container'>
                  <input id='am-input' ref={ref} placeholder="Input Staking Amount" onChange={(e)=>handleInputAmount(e)} />
                </div>
                <p>*Max {Number(ethers.formatUnits(maxDepositWallet, decimals)).toLocaleString("en-US")} $ELMO per wallet</p>
            </div>
            <div className='s-title'>
              How much of your rewards you want to burn ?
            </div>
            <div className='burn-options'>
              <button onClick={()=>handleBurnOption(1)} className={`burn${burnOption === 1 || burnChoosen === 1  ? ' selected' : ''}${burnChoosen !== 1 && burnChoosen !== 0 ? ' no_interact' : ''}`}>
                25%
              </button>
              <button onClick={()=>handleBurnOption(2)} className={`burn${burnOption === 2 || burnChoosen === 2 ? ' selected' : ''}${burnChoosen !== 2 && burnChoosen !== 0 ? ' no_interact' : ''}`}>
                50%
              </button>
              <button onClick={()=>handleBurnOption(3)} className={`burn${burnOption === 3 || burnChoosen === 3 ? ' selected' : ''}${burnChoosen !== 3 && burnChoosen !== 0 ? ' no_interact' : ''}`}>
                75%
              </button>
            </div>

            <button disabled={amount === 0 || amount === "0"} className={!signer || amount === 0 || amount === "0" ? 'stake-button disabled' : 'stake-button'} onClick={()=> approved ? handleInput() : handleApprove()}>
                {!signer ? "Connect wallet" : signer && approved ? "Stake" : "Approve"}
            </button>

            {signer ? (
              <>
                <div className='actions'>
                  <button disabled={!hasStacked || pending === 0 || isCooldown} 
                      className={!signer || !hasStacked || isCooldown || pending === 0 ? 'claim disabled' : 'claim'} onClick={()=> handleClaimReward()}>
                      {!signer ? "Connect wallet" : !isCooldown ? "Claim Rewards" : "Claim Rewards disabled" }
                  </button>
                  <button disabled={!hasStacked || isCooldown} 
                      className={!signer || !hasStacked || isCooldown ? 'compound disabled' : 'compound'} onClick={()=> handleCompound()}>
                      {!signer ? "Connect wallet" : !isCooldown ? "Compound" : "Compound disabled" }
                  </button>
                </div>
                <div className='actions sc'>
                  <button disabled={!hasStacked || isCooldown} className={!signer || !hasStacked || isCooldown ? 'emergency disabled' : 'emergency'} onClick={()=> handleEmergencyMessage()}>
                      {!signer ? "Connect wallet" : !isCooldown ? "Emergency (10% fee)" : "Emergency disabled" }
                  </button>
                  {hasStacked && isCooldown ? (
                    <>
                      {countDownCompleted ? (
                        <button disabled={!hasStacked} className={!signer || !hasStacked ? 'unstake disabled' : 'unstake'} onClick={()=> handleUnstake()}>
                            {!signer ? "Connect wallet" : "Unstake" }
                        </button>
                      ) : (
                        <button className='unstake'>
                          <Countdown 
                            date={moment(Number(widthdrawInitTime) * 1000).add(Number(delayTime), "seconds").toDate().getTime()} 
                            renderer={renderer} 
                            onComplete={e => { setCountDownCompleted(true) }} 
                          />
                        </button>
                      )
                      }
                    </>
                    ) : (
                    <>
                      <button disabled={!hasStacked} className={!signer || !hasStacked ? 'unstake disabled' : 'unstake'} onClick={()=> handleInitUnstakeMessage()}>
                          {!signer ? "Connect wallet" : "Unstake (14d cooldown)" }
                      </button>
                    </>
                    )}
                </div>
                <div className='cards _i'>
                  <div className='card'>
                    <div className='card-title'>Amount Staked</div>
                    <div className='card-info'>{Number(ethers.formatUnits(stackedBal, decimals)).toLocaleString("en-US")} $ELMO</div>
                  </div>
                  <div className='card'>
                    <div className='card-title'>Pending Rewards</div>
                    <div className='card-info'>{Number(ethers.formatUnits(pending, decimals)).toLocaleString("en-US")} $ELMO</div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='footer'>
          <div className='separator'></div>
          <div className='info'>
            <img alt='stake' src={stake_img02} />
            <div className='title_and_subtitle'>
              <div className='title'>Steak to earn & burn</div>
              <div className='subtitle'>Don't have $ELMO to get a piece of Steak?</div>
            </div>
          </div>
          <div className='actions'>
            <a className='buy' target='_blank'  rel="noreferrer" href="https://app.uniswap.org/swap?theme=dark&&inputCurrency=ETH&outputCurrency=0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0">Buy Now</a>
            <a className='join' target='_blank'  rel="noreferrer" href="https://t.me/RealElmoERC">Join the community</a>
          </div>
          <div className='actions'>
            <a className='nfts' target='_blank'  rel="noreferrer" href="https://nfts.elmoerc.io/">Get your NFT Boost</a>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default StakeForm;
