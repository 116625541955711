//Header.js
import React, { useEffect, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useWalletClient } from "wagmi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer, isConnected]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className={!signer ? "header" : "header connected"}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__content">
                <button className={`header__btn ${isOpen ? 'header__btn--active' : ''}`} type="button" aria-label="header__nav" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="header__logo">
                    <div className='name_logo'>ElmoERC</div>
                    <ul className={`header__nav ${isOpen ? 'header__nav--active' : ''}`} id="header__nav">
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://www.elmoerc.io/">Website</a>
                      </li>
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://app.uniswap.org/swap?theme=dark&&inputCurrency=ETH&outputCurrency=0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0">Buy $ELMO</a>
                      </li>
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://nfts.elmoerc.io/">Mint NFTs</a>
                      </li>
                    </ul>
                    <div className='connect'>
                      <ConnectButton chainStatus="none" showBalance={false} label='Connect' />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;